<template>
  <div
    id="contents"
    :class="tabIndex == 0 ? 'ledger_raw_materials' : 'ledger_purch'"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToLedgerPage'"
          :options="[
            { title: '원자재 원장', name: 'mateiral_ledger' },
            { title: '구매처 원장', name: 'company_ledger' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">원자재 원장</a>
          </li>
          <li :class="{ active: tabIndex == 1 }">
            <a @click="SetOpenTabIndex(1)">구매처 원장</a>
          </li>
        </ul>
      </div>
      <material-ledger-form v-if="tabIndex == 0"></material-ledger-form>
      <purchase-ledger-form v-if="tabIndex == 1"></purchase-ledger-form>
    </div>
  </div>
</template>

<script>
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import MaterialLedgerForm from '@/views/forms/Material/Ledger/MaterialLedgerForm';
import PurchaseLedgerForm from '@/views/forms/Material/Ledger/PurchaseLedgerForm';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import { mapGetters, mapMutations } from 'vuex';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    MaterialLedgerForm,
    PurchaseLedgerForm,
    AsideSelectric,
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromLedgerPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToLedgerPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitLedgerPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss" scoped></style>
