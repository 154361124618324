\'<template>
  <div class="modalPopup print_modal ledger_print_modal" v-if="modalClose">
    <div class="modal_header">
      <h2 class="title">
        구매처 원장
      </h2>
      <button type="button" class="modal_close" @click="isModalClose"></button>
      <ul class="option">
        <li>
          {{ `${startDate} ~ ${endDate}` }}
        </li>
        <li>
          <h6>
            {{ checkPage }}
          </h6>
          <!-- <span> {{ convertCheck }}</span> -->
          <span>
            {{
              include_company_list > 1
                ? findInfoFromId(companys, include_company_list[0]).name
                : `${
                    findInfoFromId(companys, include_company_list[0]).name
                  }외 ${include_company_list.length - 1}개`
            }}</span
          >
        </li>
      </ul>
    </div>
    <div class="modal_body">
      <div class="mes_tbl_wrap pur_print_table">
        <table class="mes_tbl">
          <colgroup class="purchase_ledger">
            <col
              v-for="(n, index) in include_company_list.length == 1 ? 12 : 11"
              :key="index"
            />
          </colgroup>
          <thead>
            <tr>
              <th>합계</th>
              <th>일자</th>
              <th>구분</th>
              <th>구매처명</th>
              <th>원자재명</th>
              <th>수량(단위)</th>
              <th>단가</th>
              <th>공급가</th>
              <th>부가세</th>
              <th>실지출액</th>
              <th>지급액</th>
              <th v-if="include_company_list.length == 1">잔고</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in display_arr"
              :key="index"
              :class="{
                null: item.sum_date == undefined,
                default: item.sum_date == '전 잔고',
                day: item.sum_date == '일 합계',
                month: item.sum_date == '월 합계',
              }"
            >
              <td>{{ item.sum_date == undefined ? '' : item.sum_date }}</td>
              <td>
                {{
                  item.sum_date == undefined
                    ? item.input_date
                    : item.sum_date == '월 합계'
                    ? item.input_date.substr(0, 7)
                    : item.input_date
                }}
              </td>
              <td>
                {{
                  item.sum_date == undefined
                    ? item.out_cost == undefined
                      ? findInfoFromId(purchaseTypes, item.purchase_type_id)
                          .name
                      : findInfoFromId(paymentTypes, item.payment_type_id).name
                    : ''
                }}
              </td>
              <td class="text_left">
                {{
                  item.sum_date == undefined
                    ? findInfoFromId(companys, item.company_id).name
                    : ''
                }}
              </td>
              <td class="text_left">
                {{
                  item.sum_date == undefined
                    ? findInfoFromId(materials, item.material_id).name
                    : ''
                }}
              </td>
              <td class="text_right">
                {{
                  item.sum_date == undefined
                    ? item.out_cost == undefined
                      ? `${$makeComma(item.quantity)}(${
                          findInfoFromId(units, item.unit_id).name
                        })`
                      : ''
                    : ''
                }}
              </td>
              <td class="text_right">
                {{
                  Object.keys(item).includes('purchase_type_id')
                    ? `${$makeComma(item.cost)}`
                    : ''
                }}
              </td>
              <td class="text_right">
                {{
                  item.sum_date == undefined
                    ? item.out_cost == undefined
                      ? `${$makeComma(item.supply_value)}`
                      : ''
                    : ''
                }}
              </td>
              <td class="text_right">
                {{
                  item.sum_date == undefined
                    ? item.out_cost == undefined
                      ? `${$makeComma(item.tax)}`
                      : ''
                    : ''
                }}
              </td>
              <td class="text_right">
                {{
                  item.total_cost == undefined
                    ? ''
                    : `${$makeComma(item.total_cost)}`
                }}
              </td>
              <td class="text_right">
                {{
                  item.out_cost == undefined
                    ? ''
                    : `${$makeComma(item.out_cost)}`
                }}
              </td>
              <td v-if="include_company_list.length == 1" class="text_right">
                {{
                  item.curr_balance == undefined
                    ? ''
                    : `${$makeComma(item.curr_balance)}`
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-show="false" class="page_wrap">
        <div class="page" v-for="(n, index) in devidePage" :key="index">
          <div class="modal_header">
            <p class="times">
              {{ str_time }}
            </p>
            <h2 class="title">
              구매처 원장
            </h2>
            <p class="page_num">
              page: {{ `${index + 1}/${devidePage.length}` }}
            </p>
            <button
              type="button"
              class="modal_close"
              @click="isModalClose"
            ></button>
            <ul class="option">
              <li>
                {{ `(${startDate} ~ ${endDate})` }}
              </li>
              <li>
                <h6>
                  {{ checkPage }}
                </h6>
                <!-- <span> {{ convertCheck }}</span> -->
                <span>
                  {{
                    include_company_list > 1
                      ? findInfoFromId(companys, include_company_list[0]).name
                      : `${
                          findInfoFromId(companys, include_company_list[0]).name
                        }외 ${include_company_list.length - 1}개`
                  }}</span
                >
              </li>
            </ul>
          </div>
          <div class="mes_tbl_wrap pur_print_table">
            <table class="mes_tbl">
              <colgroup class="purchase_ledger">
                <col
                  v-for="(n, index) in include_company_list.length == 1
                    ? 12
                    : 11"
                  :key="index"
                />
              </colgroup>
              <thead>
                <tr>
                  <th>합계</th>
                  <th>일자</th>
                  <th>구분</th>
                  <th>구매처명</th>
                  <th>원자재명</th>
                  <th>수량(단위)</th>
                  <th>단가</th>
                  <th>공급가</th>
                  <th>부가세</th>
                  <th>실지출액</th>
                  <th>지급액</th>
                  <th v-if="include_company_list.length == 1">잔고</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index2) in devidePage[index]"
                  :key="index2"
                  :class="{
                    null: item.sum_date == undefined,
                    default: item.sum_date == '전 잔고',
                    day: item.sum_date == '일 합계',
                    month: item.sum_date == '월 합계',
                  }"
                >
                  <td>{{ item.sum_date == undefined ? '' : item.sum_date }}</td>
                  <td>
                    {{
                      item.sum_date == undefined
                        ? item.input_date
                        : item.sum_date == '월 합계'
                        ? item.input_date.substr(0, 7)
                        : item.input_date
                    }}
                  </td>
                  <td>
                    {{
                      item.sum_date == undefined
                        ? item.out_cost == undefined
                          ? findInfoFromId(purchaseTypes, item.purchase_type_id)
                              .name
                          : findInfoFromId(paymentTypes, item.payment_type_id)
                              .name
                        : ''
                    }}
                  </td>
                  <td class="text_left ellipsis">
                    {{
                      item.sum_date == undefined
                        ? findInfoFromId(companys, item.company_id).name
                        : ''
                    }}
                  </td>
                  <td class="text_left ellipsis">
                    {{
                      item.sum_date == undefined
                        ? findInfoFromId(materials, item.material_id).name
                        : ''
                    }}
                  </td>
                  <td class="text_right">
                    {{
                      item.sum_date == undefined
                        ? item.out_cost == undefined
                          ? `${$makeComma(item.quantity)}(${
                              findInfoFromId(units, item.unit_id).name
                            })`
                          : ''
                        : ''
                    }}
                  </td>
                  <td class="text_right">
                    {{
                      Object.keys(item).includes('purchase_type_id')
                        ? `${$makeComma(item.cost)}`
                        : ''
                    }}
                  </td>
                  <td class="text_right">
                    {{
                      item.sum_date == undefined
                        ? item.out_cost == undefined
                          ? `${$makeComma(item.supply_value)}`
                          : ''
                        : ''
                    }}
                  </td>
                  <td class="text_right">
                    {{
                      item.sum_date == undefined
                        ? item.out_cost == undefined
                          ? `${$makeComma(item.tax)}`
                          : ''
                        : ''
                    }}
                  </td>
                  <td class="text_right">
                    {{
                      item.total_cost == undefined
                        ? ''
                        : `${$makeComma(item.total_cost)}`
                    }}
                  </td>
                  <td class="text_right">
                    {{
                      item.out_cost == undefined
                        ? ''
                        : `${$makeComma(item.out_cost)}`
                    }}
                  </td>
                  <td
                    v-if="include_company_list.length == 1"
                    class="text_right"
                  >
                    {{
                      item.curr_balance == undefined
                        ? ''
                        : `${$makeComma(item.curr_balance)}`
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal_footer">
      <button type="button" class="btn_sub2 btn_print" @click="handlerPrint">
        <i class="ico_print"></i>출력하기
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import { yyyymmdd } from '@/utils/func';
import { format년월일요일시분초 } from '@/utils/filters';

export default {
  props: ['include_company_list', 'startDate', 'endDate', 'filterCheck'],
  mixins: [FETCH_MIXIN, DRAG_MODAL_MIXIN],
  data() {
    return {
      modalClose: true,
      checkList: [],
      checkPage: '구매처',
      str_time: null,
    };
  },
  computed: {
    ...mapGetters({
      start_date: 'getStartDateFromPurchaseLedger',
      end_date: 'getEndDateFromPurchaseLedger',
      companys: 'getCompany',
      materials: 'getMaterial',
      order_type: 'getOrderPurchaseType',
      return_type: 'getReturnPurchaseType',
      draft_type: 'getDraftPurchaseType',
      purchase_account: 'getPurchaseAccountFromPurchaseLedger',
      material_in_out_types: 'getMaterialInOutType',
      purchaseTypes: 'getPurchaseTypeNotSort',
      units: 'getUnitCodes',
      payments: 'getPayments',
      paymentTypes: 'getPaymentTypes',
      // include_company_list: 'getIncludeCompanyListFromPurchaseLedger',
    }),

    // convertCheck() {
    //   let clone = this.lodash.clonedeep(this.filterCheck);
    //   console.log(this.filterCheck);
    //   clone.splice(0, 1);
    //   clone = clone.map(x => {
    //     x = this.findInfoFromId(this.resource_type, x).name;
    //     return x;
    //   });
    //   let cloneList = clone.toString().replace(/,/gi, ', ');
    //   return cloneList;
    // },
    mapAccount() {
      try {
        if (this.purchase_account != undefined) {
          const purchase_account = this.lodash.clonedeep(this.purchase_account);
          purchase_account.forEach(element => {
            element.purchase_material_list.forEach(el2 => {
              el2.company_id = element.purchase.company_id;
              el2.vat_id = element.purchase.vat_id;
              el2.purchase_type_id = element.purchase.purchase_type_id;
            });
          });

          // let map = this.lodash.clonedeep(
          //   purchase_account.map(x => x.purchase_material_list),
          // );
          let map = this.lodash.clonedeep(purchase_account).map(x => {
            const create_time_temp = this.lodash.clonedeep(x.create_time);
            x = x.purchase_material_list;
            x.map(y => {
              y.create_time = create_time_temp;
              return y;
            });
            return x;
          });

          if (map.length > 0) {
            map = map.reduce((a, b) => a.concat(b));

            for (let index = 0; index < map.length; index++) {
              const targetEl = map[index];

              // 여기서 에러..  'create_time' of undefined
              // targetEl.create_time = targetEl.material_in_out_list.find(y =>
              //   this.material_in_out_types
              //     .filter(z =>
              //       ['buy in', 'return out', 'discard out'].includes(z.detail),
              //     )
              //     .map(k => k.id)
              //     .includes(y.material_in_out_type_id),
              // ).create_time;
              map[index].input_date = map[index].create_time.substr(0, 10);
              if (
                targetEl.purchase_type_id ==
                this.purchaseTypes.find(x => x.detail == 'return purchase').id
              ) {
                targetEl.supply_value *= -1;
                targetEl.tax *= -1;
                targetEl.total_cost *= -1;
              }
            }
          }

          const payments = this.lodash.clonedeep(this.payments);
          payments.forEach(element => {
            element.out_cost = element.total_value;
            element.create_time = element.input_date + ' 23:59:99.999999';
            map.push(element);
          });
          return map;
        } else {
          return [];
        }
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    group_day() {
      let clone = this.lodash.clonedeep(
        this.mapAccount.filter(
          x =>
            this.include_company_list.includes(x.company_id) &&
            (x.total_cost != 0 || x.out_cost != 0),
        ),
      );

      if (clone == undefined) {
        return;
      }

      const obj = clone
        .sort(
          (a, b) =>
            Number(
              a.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ) -
            Number(
              b.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ),
        )
        .reduce(function(rv, x) {
          (rv[x['input_date']] = rv[x['input_date']] || []).push(x);
          return rv;
        }, {});

      let arr = [];
      let count = 100000;
      console.log(obj);
      const reducer = (accumulator, currentValue) =>
        accumulator + Number(currentValue.toFixed(6));

      for (let k in obj) {
        arr.push({
          date: k,
          month: k.substr(0, 7),
          element: obj[k],
        });

        const objInTemp = obj[k]
          .filter(
            x =>
              x.purchase_type_id != undefined &&
              x.purchase_type_id != this.draft_type[0].id,
          )
          .map(x => x.total_cost);

        const objOutTemp = obj[k]
          .filter(x => x.payment_type_id != undefined)
          .map(x => x.total_value);
        // obj[k]
        //   .filter(
        //     x =>
        //       x.purchase_type_id ==
        //         this.materialInOutType.find(x => x.detail == 'using out').id ||
        //       x.purchase_type_id ==
        //         this.materialInOutType.find(x => x.detail == 'return out').id,
        //   )
        //   .map(x => x.quantity);

        const sumInCost = Number(objInTemp.reduce(reducer, 0).toFixed(6));
        const sumOutCost = Number(objOutTemp.reduce(reducer, 0).toFixed(6));
        obj[k].push({
          sum_date: '일 합계',
          input_date: k,
          id: count++,
          total_cost: sumInCost,
          out_cost: sumOutCost,
        });
      }

      return arr;
    },
    group_month() {
      const obj = this.group_day.reduce(function(rv, x) {
        (rv[x['month']] = rv[x['month']] || []).push(x);
        return rv;
      }, {});
      let arr = [];
      let count = 9999999;
      for (let k in obj) {
        arr.push({
          date: k,
          element: obj[k],
        });
        const day_list = obj[k]
          .map(x => x.element)
          .reduce((a, b) => a.concat(b))
          .filter(x => x.sum_date !== undefined);
        const sumAllInCost = day_list
          .map(x => x.total_cost)
          .reduce((a, b) => this.$decimalAdd(a, b));
        const sumAllOutCost = day_list
          .map(x => x.out_cost)
          .reduce((a, b) => this.$decimalAdd(a, b));

        obj[k].push({
          element: {
            sum_date: '월 합계',
            input_date: k + '-00',
            id: count++,
            total_cost: sumAllInCost,
            out_cost: sumAllOutCost,
          },
        });
      }
      return arr;
    },
    display_arr() {
      try {
        const temp = this.group_month.map(x => x.element);

        let list_ = [];

        if (temp !== undefined) {
          temp.forEach(e => {
            e.forEach(x => {
              list_ = list_.concat(x.element);
            });
          });
        }
        // return list_;

        const reverse = list_.reverse();

        // return reverse;
        if (this.include_company_list.length == 1) {
          const company_balance = this.lodash.clonedeep(this.company_balance);
          let curr_balance = company_balance;

          reverse.forEach(x => {
            if (x.sum_date == undefined) {
              if (x.material_in_out_list != undefined) {
                x.curr_balance = curr_balance;
                curr_balance -= x.total_cost;
              } else {
                x.curr_balance = curr_balance;
                curr_balance += x.out_cost;
              }
            } else {
              x.curr_balance = curr_balance;
            }
          });
          reverse.push({
            input_date: this.lodash.clonedeep(this.start_date),
            sum_date: '전 잔고',
            curr_balance: curr_balance,
          });
        }
        // return reverse;

        return reverse
          .reverse()
          .filter(
            x =>
              Number(x.input_date.replace(/-/gi, '')) <=
                Number(this.end_date.replace(/-/gi, '')) &&
              (this.$makeNumber(x.out_cost) != 0 ||
                this.$makeNumber(x.total_cost) != 0 ||
                Object.keys(x).includes('sum_date')),
          );
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    company_balance() {
      if (this.include_company_list.length == 1) {
        if (
          this.companys.find(x => x.id == this.include_company_list[0]) !=
          undefined
        ) {
          return this.companys.find(x => x.id == this.include_company_list[0])
            .curr_balance;
        } else return 0;
      } else {
        return 0;
      }
    },
    devidePage() {
      let clone = this.lodash.clonedeep(this.display_arr);
      let arr = [];
      clone.forEach((el, index) => {
        if (index % 23 == 0) {
          arr.push(clone.slice(index, index + 23));
        }
      });
      return arr;
    },
  },
  methods: {
    isModalClose() {
      this.$emit('onclose');
    },
    handlerPrint() {
      const app = document.querySelector('#app');
      const ledgerHeader = document.querySelector(
        '.ledger_print_modal .modal_header',
      );
      const ledgerTable = document.querySelector(
        '.ledger_print_modal .modal_body .page_wrap',
      );
      ledgerTable.style.display = 'block';
      const printDiv = document.createElement('div');
      let ledgerHeaderClone = ledgerHeader.cloneNode(true);
      let ledgerTableClone = ledgerTable.cloneNode(true);
      let ledgerTrClone = ledgerTableClone.querySelectorAll('tr');
      ledgerTrClone.forEach(el => {
        let ledgerTd = el.querySelectorAll('td');
        ledgerTd.forEach((el2, index) => {
          index == 1 ? (el2.innerText = el2.innerText.substr(6, 7)) : '';
        });
      });
      printDiv.className = 'ledger_print_wrap';
      printDiv.className += ' ledger_print_wrap2';
      printDiv.className += ' ledger_print_wrap5';
      document.body.appendChild(printDiv);
      printDiv.append(ledgerHeaderClone, ledgerTableClone);
      app.style.display = 'none';
      window.print();
      ledgerTable.style.display = 'none';
      app.style.display = 'block';
      document.body.removeChild(printDiv);
    },
  },
  async created() {
    if (this.start_date == null) {
      var date = new Date();
      await this.$store.commit('setEndDateToPurchaseLedger', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 7));
      await this.$store.commit('setStartDateToPurchaseLedger', yyyymmdd(date2));
    }
    const newDate = new Date();
    this.str_time = format년월일요일시분초(newDate);
    await this.FETCH('FETCH_COMPANY');
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY');
    }
    if (this.material_in_out_types.length < 1) {
      await this.FETCH('FETCH_MATERIAL_IN_OUT_TYPE');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT');
    }
    if (this.purchaseTypes.length < 1) {
      await this.FETCH('FETCH_PURCHASE_TYPE');
    }
    if (this.paymentTypes.length < 1) {
      await this.FETCH('FETCH_PAYMENTS_TYPE');
    }
    await this.FETCH_WITH_PAYLOAD(
      'FETCH_PAYMENT',
      {
        start: this.start_date,
        end: '2500-01-01',
      },
      '지급내역',
    );
    await this.FETCH_WITH_PAYLOAD(
      'FETCH_PURCHASE_ACCOUNT_DAY_ALL_TO_PURCHASE_LEDGER',
      {
        start: this.start_date,
        end: '2500-01-01',
      },
      '구매 내역',
    );
  },
};
</script>

<style lang="scss" scoped>
@media print {
  @page {
    // size: A4 !important;
    margin: auto 0 0 !important;
  }
  @page :first {
    margin-top: 0 !important;
  }
}
</style>
