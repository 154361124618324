<template>
  <div class="article">
    <div class="tbl_wrap">
      <form>
        <div class="tbl_option">
          <div class="search_opt">
            <div class="input_text">
              <label>조회 기간</label>
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="start_date"
                @change="setStartDate($event)"
                v-if="showSearchDate"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="end_date"
                @change="setEndDate($event)"
                v-if="showSearchDate"
              />
            </div>
            <button
              type="button"
              class="btn_sub1 refresh_btn"
              @click="refreshData"
            >
              재조회
            </button>
            <button
              class="btn_sub2 btn_print"
              v-if="include_company_list.length > 0"
              @click.prevent="openModal"
            >
              <i class="ico_print"></i>출력
            </button>
            <!-- <button class="excel_btn">엑셀 저장</button> -->
            <purchase-print-modal
              v-if="isLedgerModalOpen"
              :include_company_list="include_company_list"
              :startDate="start_date"
              :endDate="end_date"
              :checkPage="checkPage"
              @onclose="closeModal"
            ></purchase-print-modal>
            <div
              class="modal_layer"
              v-if="isLedgerModalOpen"
              @click="closeModal"
            ></div>
            <button
              v-if="include_company_list.length > 0"
              class="excel_btn"
              @click.prevent="exportExcel"
            >
              엑셀로 저장
            </button>
          </div>
          <div class="ledger_opt">
            <button
              type="button"
              class="btn_ledger"
              @click="showCompanySearch = true"
            >
              구매처 선택
            </button>

            <transition-group
              name="selected"
              mode="in-out"
              tag="ul"
              type="transition"
              class="selected_items_wrap"
              v-if="include_company_list.length > 0"
            >
              <li
                class="selected_item"
                v-for="(item, index) in include_company_list"
                :key="item"
              >
                <span>{{ findInfoFromId(companys, item).name }}</span>
                <button
                  type="button"
                  class="delete_item"
                  @click="include_company_list.splice(index, 1)"
                ></button>
              </li>
            </transition-group>
          </div>
        </div>
      </form>
      <div
        class="ledger_info purch_info"
        v-if="
          include_company_list != undefined && include_company_list.length == 1
        "
      >
        <div class="led_info">
          <h5>구매처 정보</h5>
          <table>
            <tr>
              <th>구매처명</th>
              <td>
                {{ findInfoFromId(companys, include_company_list[0]).name }}
              </td>
              <th>대표자명</th>
              <td>
                {{
                  findInfoFromId(companys, include_company_list[0])
                    .representative
                }}
              </td>
              <th>사업자 번호</th>
              <td>
                {{ findInfoFromId(companys, include_company_list[0]).saup_no }}
              </td>
            </tr>
            <tr>
              <th>취급 원자재</th>
              <td>
                {{ getPrimaryMaterial() }}
              </td>
              <th>거래개시일</th>
              <td>
                {{
                  findInfoFromId(companys, include_company_list[0])
                    .business_date
                }}
              </td>
              <th>현잔고</th>
              <td>&#8361; {{ $makeComma(company_balance) }}</td>
            </tr>
            <tr>
              <th>주소</th>
              <td colspan="5">
                {{
                  findInfoFromId(companys, include_company_list[0]).address !=
                  null
                    ? `${
                        findInfoFromId(companys, include_company_list[0])
                          .address
                      }`
                    : ''
                }}
                {{
                  findInfoFromId(companys, include_company_list[0])
                    .detail_address != null
                    ? `${
                        findInfoFromId(companys, include_company_list[0])
                          .detail_address
                      }`
                    : ''
                }}
              </td>
            </tr>
          </table>
        </div>
        <div class="led_main">
          <h5>구매 원자재</h5>
          <table>
            <tr>
              <th>1위</th>
              <td>
                {{
                  group_material.length > 0
                    ? findInfoFromId(materials, group_material[0].material_id)
                        .name
                    : ''
                }}
              </td>
            </tr>
            <tr>
              <th>2위</th>
              <td>
                {{
                  group_material.length > 1
                    ? findInfoFromId(materials, group_material[1].material_id)
                        .name
                    : ''
                }}
              </td>
            </tr>
            <tr>
              <th>3위</th>
              <td>
                {{
                  group_material.length > 2
                    ? findInfoFromId(materials, group_material[2].material_id)
                        .name
                    : ''
                }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <h6>
        조회수 :
        {{
          display_arr.filter(x => x.sum_date == null || x.sum_date == undefined)
            .length
        }}건
      </h6>
      <div class="mes_tbl_wrap pur_print_table">
        <table class="mes_tbl" id="excel_table">
          <colgroup class="purchase_ledger">
            <col
              v-for="(n, index) in include_company_list.length == 1 ? 12 : 11"
              :key="index"
            />
          </colgroup>
          <thead>
            <tr>
              <th :colspan="include_company_list.length == 1 ? 12 : 11">
                <h2>구매처 원장</h2>
              </th>
            </tr>
            <tr>
              <th>조회기간</th>
              <td :colspan="include_company_list.length == 1 ? 11 : 10">
                <span>{{ `${start_date}~${end_date}` }}</span>
              </td>
              <!-- <th
                v-for="(n, index) in include_company_list.length == 1 ? 10 : 9"
                :key="index"
              ></th> -->
            </tr>
            <tr>
              <th>구매처</th>
              <td
                v-for="(n, index) in include_company_list.length == 1 ? 11 : 10"
                :key="index"
              >
                {{ addComapny(index) }}
              </td>
            </tr>
            <tr></tr>
            <tr class="table_title">
              <th>합계</th>
              <th>일자</th>
              <th>구분</th>
              <th>구매처명</th>
              <th>원자재명</th>
              <th>수량(단위)</th>
              <th>단가</th>
              <th>공급가</th>
              <th>부가세</th>
              <th>실지출액</th>
              <th>지급액</th>
              <th v-if="include_company_list.length == 1">잔고</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in display_arr"
              :key="index"
              :class="{
                null: item.sum_date == undefined,
                default: item.sum_date == '전 잔고',
                day: item.sum_date == '일 합계',
                month: item.sum_date == '월 합계',
              }"
            >
              <td>
                <center>
                  {{ item.sum_date == undefined ? '' : item.sum_date }}
                </center>
              </td>
              <td>
                <center>
                  {{
                    item.sum_date == undefined
                      ? item.input_date
                      : item.sum_date == '월 합계'
                      ? item.input_date.substr(0, 7)
                      : item.input_date
                  }}
                </center>
              </td>
              <td>
                <center>
                  {{
                    item.sum_date == undefined
                      ? item.out_cost == undefined
                        ? findInfoFromId(purchaseTypes, item.purchase_type_id)
                            .name
                        : findInfoFromId(paymentTypes, item.payment_type_id)
                            .name
                      : ''
                  }}
                </center>
              </td>
              <td class="text_left">
                {{
                  item.sum_date == undefined
                    ? findInfoFromId(companys, item.company_id).name
                    : ''
                }}
              </td>
              <td class="text_left">
                {{
                  item.sum_date == undefined
                    ? findInfoFromId(materials, item.material_id).name
                    : ''
                }}
              </td>
              <td class="text_right">
                <center>
                  {{
                    item.sum_date == undefined
                      ? item.out_cost == undefined
                        ? `${$makeComma(item.quantity)}(${
                            findInfoFromId(units, item.unit_id).name
                          })`
                        : ''
                      : ''
                  }}
                </center>
              </td>
              <td class="text_right">
                {{
                  Object.keys(item).includes('purchase_type_id')
                    ? `₩ ${$makeComma(item.cost)}`
                    : ''
                }}
              </td>
              <td class="text_right">
                {{
                  item.sum_date == undefined
                    ? item.out_cost == undefined
                      ? `₩ ${$makeComma(item.supply_value)}`
                      : ''
                    : ''
                }}
              </td>
              <td class="text_right">
                {{
                  item.sum_date == undefined
                    ? item.out_cost == undefined
                      ? `₩ ${$makeComma(item.tax)}`
                      : ''
                    : ''
                }}
              </td>
              <td class="text_right">
                {{
                  item.total_cost == undefined
                    ? ''
                    : `₩ ${$makeComma(item.total_cost)}`
                }}
              </td>
              <td class="text_right">
                {{
                  item.out_cost == undefined
                    ? ''
                    : `₩ ${$makeComma(item.out_cost)}`
                }}
              </td>
              <td v-if="include_company_list.length == 1" class="text_right">
                {{
                  item.curr_balance == undefined
                    ? ''
                    : `₩ ${$makeComma(item.curr_balance)}`
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <company-search
      v-if="showCompanySearch"
      :search_type="2"
      @onclose="showCompanySearch = false"
      @onselect="
        $event => {
          pushIncludeCompany($event);
          showCompanySearch = false;
        }
      "
    ></company-search>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import { yyyymmdd } from '@/utils/func';
import CompanySearch from '@/layouts/components/search-popup/CompanySearch';
import PurchasePrintModal from '@/layouts/components/PurchasePrintModal';
export default {
  mixins: [FETCH_MIXIN],
  components: {
    CompanySearch,
    PurchasePrintModal,
  },
  data() {
    return {
      showSearchDate: false,
      showCompanySearch: false,
      isLedgerModalOpen: false,
      checkPage: '구매처',
    };
  },
  computed: {
    ...mapGetters({
      purchase_company_balance: 'getPurchaseCompanyBalanceList',
      purchase_account: 'getPurchaseAccountFromPurchaseLedger',
      start_date: 'getStartDateFromPurchaseLedger',
      end_date: 'getEndDateFromPurchaseLedger',
      include_company_list: 'getIncludeCompanyListFromPurchaseLedger',
      companys: 'getCompany',
      materials: 'getMaterial',
      material_in_out_types: 'getMaterialInOutType',
      purchaseTypes: 'getPurchaseTypeNotSort',
      order_type: 'getOrderPurchaseType',
      return_type: 'getReturnPurchaseType',
      draft_type: 'getDraftPurchaseType',
      units: 'getUnitCodes',
      material_companys: 'getMaterialCompanyNotSort',
      payments: 'getPayments',
      paymentTypes: 'getPaymentTypes',
    }),

    mapAccount() {
      try {
        if (this.purchase_account != undefined) {
          const purchase_account = this.lodash.clonedeep(this.purchase_account);
          purchase_account.forEach(element => {
            element.purchase_material_list.forEach(el2 => {
              el2.company_id = element.purchase.company_id;
              el2.vat_id = element.purchase.vat_id;
              el2.purchase_type_id = element.purchase.purchase_type_id;
            });
          });

          // let map = this.lodash.clonedeep(
          //   purchase_account.map(x => x.purchase_material_list),
          // );
          let map = this.lodash.clonedeep(purchase_account).map(x => {
            const create_time_temp = this.lodash.clonedeep(x.create_time);
            x = x.purchase_material_list;
            x.map(y => {
              y.create_time = create_time_temp;
              return y;
            });
            return x;
          });

          if (map.length > 0) {
            map = map.reduce((a, b) => a.concat(b));

            for (let index = 0; index < map.length; index++) {
              const targetEl = map[index];

              // 여기서 에러..  'create_time' of undefined
              // targetEl.create_time = targetEl.material_in_out_list.find(y =>
              //   this.material_in_out_types
              //     .filter(z =>
              //       ['buy in', 'return out', 'discard out'].includes(z.detail),
              //     )
              //     .map(k => k.id)
              //     .includes(y.material_in_out_type_id),
              // ).create_time;
              map[index].input_date = map[index].create_time.substr(0, 10);
              if (
                targetEl.purchase_type_id ==
                this.purchaseTypes.find(x => x.detail == 'return purchase').id
              ) {
                targetEl.supply_value *= -1;
                targetEl.tax *= -1;
                targetEl.total_cost *= -1;
              }
            }
          }

          const payments = this.lodash.clonedeep(this.payments);
          payments.forEach(element => {
            element.out_cost = element.total_value;
            element.create_time = element.input_date + ' 23:59:99.999999';
            map.push(element);
          });
          return map;
        } else {
          return [];
        }
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    group_day() {
      let clone = this.lodash.clonedeep(
        this.mapAccount.filter(
          x =>
            this.include_company_list.includes(x.company_id) &&
            (x.total_cost != 0 || x.out_cost != 0),
        ),
      );

      if (clone == undefined) {
        return;
      }

      const obj = clone
        .sort(
          (a, b) =>
            Number(
              a.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ) -
            Number(
              b.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ),
        )
        .reduce(function(rv, x) {
          (rv[x['input_date']] = rv[x['input_date']] || []).push(x);
          return rv;
        }, {});

      let arr = [];
      let count = 100000;
      console.log(obj);
      const reducer = (accumulator, currentValue) =>
        accumulator + Number(currentValue.toFixed(6));

      for (let k in obj) {
        arr.push({
          date: k,
          month: k.substr(0, 7),
          element: obj[k],
        });

        const objInTemp = obj[k]
          .filter(
            x =>
              x.purchase_type_id != undefined &&
              x.purchase_type_id != this.draft_type[0].id,
          )
          .map(x => x.total_cost);

        const objOutTemp = obj[k]
          .filter(x => x.payment_type_id != undefined)
          .map(x => x.total_value);
        // obj[k]
        //   .filter(
        //     x =>
        //       x.purchase_type_id ==
        //         this.materialInOutType.find(x => x.detail == 'using out').id ||
        //       x.purchase_type_id ==
        //         this.materialInOutType.find(x => x.detail == 'return out').id,
        //   )
        //   .map(x => x.quantity);

        const sumInCost = Number(objInTemp.reduce(reducer, 0).toFixed(6));
        const sumOutCost = Number(objOutTemp.reduce(reducer, 0).toFixed(6));
        obj[k].push({
          sum_date: '일 합계',
          input_date: k,
          id: count++,
          total_cost: sumInCost,
          out_cost: sumOutCost,
        });
      }

      return arr;
    },
    group_month() {
      const obj = this.group_day.reduce(function(rv, x) {
        (rv[x['month']] = rv[x['month']] || []).push(x);
        return rv;
      }, {});
      let arr = [];
      let count = 9999999;
      for (let k in obj) {
        arr.push({
          date: k,
          element: obj[k],
        });
        const day_list = obj[k]
          .map(x => x.element)
          .reduce((a, b) => a.concat(b))
          .filter(x => x.sum_date !== undefined);
        const sumAllInCost = day_list
          .map(x => x.total_cost)
          .reduce((a, b) => this.$decimalAdd(a, b));
        const sumAllOutCost = day_list
          .map(x => x.out_cost)
          .reduce((a, b) => this.$decimalAdd(a, b));

        obj[k].push({
          element: {
            sum_date: '월 합계',
            input_date: k + '-00',
            id: count++,
            total_cost: sumAllInCost,
            out_cost: sumAllOutCost,
          },
        });
      }
      return arr;
    },
    display_arr() {
      try {
        const temp = this.group_month.map(x => x.element);

        let list_ = [];

        if (temp !== undefined) {
          temp.forEach(e => {
            e.forEach(x => {
              list_ = list_.concat(x.element);
            });
          });
        }
        // return list_;

        const reverse = list_.reverse();

        // return reverse;
        if (this.include_company_list.length == 1) {
          const company_balance = this.lodash.clonedeep(this.company_balance);
          let curr_balance = company_balance;

          reverse.forEach(x => {
            if (x.sum_date == undefined) {
              if (x.material_in_out_list != undefined) {
                x.curr_balance = curr_balance;
                curr_balance -= x.total_cost;
              } else {
                x.curr_balance = curr_balance;
                curr_balance += x.out_cost;
              }
            } else {
              x.curr_balance = curr_balance;
            }
          });
          reverse.push({
            input_date: this.lodash.clonedeep(this.start_date),
            sum_date: '전 잔고',
            curr_balance: curr_balance,
          });
        }
        // return reverse;

        return reverse
          .reverse()
          .filter(
            x =>
              Number(x.input_date.replace(/-/gi, '')) <=
                Number(this.end_date.replace(/-/gi, '')) &&
              (this.$makeNumber(x.out_cost) != 0 ||
                this.$makeNumber(x.total_cost) != 0 ||
                Object.keys(x).includes('sum_date')),
          );
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    company_balance() {
      if (this.include_company_list.length == 1) {
        if (
          this.companys.find(x => x.id == this.include_company_list[0]) !=
          undefined
        ) {
          return this.companys.find(x => x.id == this.include_company_list[0])
            .curr_balance;
        } else return 0;
      } else {
        return 0;
      }
    },
    group_material() {
      let clone = this.lodash.clonedeep(this.display_arr);
      const obj = clone.reduce(function(rv, x) {
        (rv[x['material_id']] = rv[x['material_id']] || []).push(x);
        return rv;
      }, {});
      console.log(obj);
      let list_ = [];
      for (let k in obj) {
        if (k != 'undefined') {
          list_.push({
            material_id: k,
            count: obj[k].length,
          });
        }
      }
      return list_.sort((a, b) => b.count - a.count);
    },
  },
  methods: {
    openModal() {
      if (
        this.include_company_list !== undefined ||
        this.include_company_list.length > 0
      ) {
        this.isLedgerModalOpen = true;
      }
    },
    closeModal() {
      let ledgerTableClone = document.querySelector(
        '.ledger_print_modal .modal_body',
      );
      let ledgerTable = ledgerTableClone.querySelector('.pur_print_table');
      ledgerTable.parentNode.removeChild(ledgerTable);
      this.isLedgerModalOpen = false;
    },
    getPrimaryMaterial() {
      try {
        const matcom = this.lodash.clonedeep(
          this.material_companys.filter(
            x => x.company_id == this.include_company_list[0],
          ),
        );
        if (matcom == undefined) {
          return [];
        }
        if (matcom.length >= 1) {
          const repre = this.findInfoFromId(
            this.materials,
            matcom[0].material_id,
          ).name;
          if (matcom.length >= 2) {
            return `${repre} 외 ${matcom.length - 1}개 자재`;
          } else {
            return repre;
          }
        } else {
          return '';
        }
      } catch (error) {
        console.log(error);
        return '';
      }
    },
    async setStartDate(e) {
      if (e.target.value != '') {
        await this.$store.commit(
          'setStartDateToPurchaseLedger',
          e.target.value,
        );
        await this.FETCH_WITH_PAYLOAD(
          'FETCH_PURCHASE_ACCOUNT_DAY_ALL_TO_PURCHASE_LEDGER',
          {
            start: this.start_date,
            end: '2500-01-01',
          },
          '구매 내역',
        );
        await this.FETCH_WITH_PAYLOAD(
          'FETCH_PAYMENT',
          {
            start: this.start_date,
            end: '2500-01-01',
          },
          '지급 내역',
        );
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        await this.$store.commit(
          'setStartDateToPurchaseLedger',
          yyyymmdd(new Date()),
        );
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        this.$store.commit('setEndDateToPurchaseLedger', e.target.value);
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToPurchaseLedger', yyyymmdd(new Date()));
      }
    },
    pushIncludeCompany(e) {
      if (!this.include_company_list.includes(e.id)) {
        this.include_company_list.push(e.id);
      }
    },
    async refreshData() {
      await this.FETCH_WITH_PAYLOAD_NO_SPINNER('FETCH_COMPANY');
      await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
        'FETCH_PURCHASE_ACCOUNT_DAY_ALL_TO_PURCHASE_LEDGER',
        {
          start: this.start_date,
          end: '2500-01-01',
        },
        '구매 내역',
      );
      await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
        'FETCH_PAYMENT',
        {
          start: this.start_date,
          end: '2500-01-01',
        },
        '지급 내역',
      );
    },
    addComapny(index) {
      if (
        this.include_company_list != undefined &&
        this.include_company_list.length > 0
      ) {
        return this.findInfoFromId(
          this.companys,
          this.include_company_list[index],
        ).name;
      } else {
        return '';
      }
    },
    exportExcel() {
      let table = document.querySelector('#excel_table');
      let styles = `<style>
          #excel_table{width: 100%;border: 1px solid #ddd;}
          tr:first-child,tr:nth-child(2),tr:nth-child(3) {display:block;} 
          tr.table_title th {background-color: #f6fafd;border-top: 1px solid #ddd;border-left: 1px solid #ddd;}
          tbody td {border-top: 1px solid #ddd;border-left: 1px solid #ddd;}
          tbody td:last-child {border-right: 1px solid #ddd;}
          tbody tr:last-child td {border-bottom: 1px solid #ddd;}
          tbody tr.default td {background-color: #f0f7fc;}
          tbody tr.day td {background-color: #f0ffee;}
          tbody tr.month td {background-color: #fffbd6;}
        </style>`;

      let uri = 'data:application/vnd.ms-excel;base64,';
      let template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->' +
        styles +
        '</head><body>{table}</body></html>';
      let base64 = function(s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      };
      let format = function(s, c) {
        return s.replace(/{(\w+)}/g, function(m, p) {
          return c[p];
        });
      };
      let ctx = {
        table: table.outerHTML.split('₩').join(''),
      };
      let a = document.createElement('a');
      a.href = uri + base64(format(template, ctx));
      a.download = `${
        this.include_company_list.length == 1
          ? this.findInfoFromId(this.companys, this.include_company_list[0])
              .name
          : `${
              this.findInfoFromId(this.companys, this.include_company_list[0])
                .name
            }외${this.include_company_list.length - 1}개`
      }_${this.start_date}~${this.end_date}.xls`;
      a.click();
    },
  },

  async created() {
    if (this.start_date == null) {
      var date = new Date();
      await this.$store.commit('setEndDateToPurchaseLedger', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 7));
      await this.$store.commit('setStartDateToPurchaseLedger', yyyymmdd(date2));
    }
    // if (this.purchase_company_balance.length < 1) {
    //   await this.FETCH('FETCH_PURCHASE_COMPANY_BALANCE');
    // }

    await this.FETCH('FETCH_COMPANY');
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY');
    }
    if (this.material_in_out_types.length < 1) {
      await this.FETCH('FETCH_MATERIAL_IN_OUT_TYPE');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT');
    }
    if (this.purchaseTypes.length < 1) {
      await this.FETCH('FETCH_PURCHASE_TYPE');
    }
    if (this.material_companys.length < 1) {
      await this.FETCH('FETCH_MATERIAL_COMPANY');
    }
    if (this.paymentTypes.length < 1) {
      await this.FETCH('FETCH_PAYMENTS_TYPE');
    }

    await this.FETCH_WITH_PAYLOAD(
      'FETCH_PAYMENT',
      {
        start: this.start_date,
        end: '2500-01-01',
      },
      '지급내역',
    );

    await this.FETCH_WITH_PAYLOAD(
      'FETCH_PURCHASE_ACCOUNT_DAY_ALL_TO_PURCHASE_LEDGER',
      {
        start: this.start_date,
        end: '2500-01-01',
      },
      '구매 내역',
    );

    this.showSearchDate = true;
  },
};
</script>

<style lang="scss" scoped>
.selected_item {
  transition: all 0.5s;
}
.selected-enter,
.selected-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.selected-leave-active {
  position: absolute;
}
</style>
